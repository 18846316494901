import React from "react"
import Layout from "../components/layout"
import Seo from "../components/SEO/Seo"
import { graphql } from "gatsby"
import "../styles/post.scss"
import { Link } from "gatsby"

export default function Post({ data, pageContext }) {
  const datos = data.strapiPost
  const cms = `https://api.bullhost.es`
  const cuerpo = datos.cuerpo.replace(/src="\//g, `src="${cms}/`)
  const urlDestacada = `${cms}${datos.imagen_destacada.url}`

  function convertirFecha(fecha) {
    return new Date(fecha).toLocaleDateString("es", {
      day: "numeric",
      month: "long",
      year: "numeric",
    })
  }
  return (
    <Layout>
      <Seo
        title={datos.titulo}
        imagen={urlDestacada}
        pathname={`/blog/${pageContext.slug}`}
        description={datos.extracto}
      />
      <section className="container primera-seccion">
        <div className="meta-post">
          <div className="categorias">
            {datos.categorias.map((value, index) => {
              return <span key={index}>{value.nombre}</span>
            })}
          </div>
          <h1>{datos.titulo}</h1>
          <div className="metadatos">
            <span className="fecha">{convertirFecha(datos.published_at)}</span>
          </div>
          <img
            src={urlDestacada}
            alt={datos.imagen_destacada.alternativeText}
          />
        </div>
        <div
          className="cuerpo cuerpo-post"
          dangerouslySetInnerHTML={{ __html: cuerpo }}
        />
      </section>
      <section className="posts-relacionados container">
        <h2 className="titulo-relacionados">Posts relacionados</h2>
        <div className="grid-posts">
          {datos.relacionados &&
            datos.relacionados.map((node, index) => {
              if (index < 3) {
                return (
                  <article
                    key={index}
                    className="card-blog-index tarjeta-relacionados"
                  >
                    <div className="card-blog-cuerpo-index">
                      <span className="texto-fecha">
                        {convertirFecha(node.published_at)}
                      </span>
                      <h3>
                        <Link
                          cover
                          bg="#1f1f1f"
                          className="titulo"
                          to={`/blog/${node.slug}/`}
                        >
                          {node.titulo}
                        </Link>
                      </h3>
                      <p className="extracto">{`${node.extracto.substring(
                        0,
                        150
                      )}...`}</p>
                    </div>
                  </article>
                )
              }
              return ""
            })}
          {datos.relacionados.length === 0 ? (
            <h4 class="sin-posts">No hay posts relacionados</h4>
          ) : (
            ""
          )}
        </div>
        <div className="enlace-volver">
          <a href="/blog/">Volver al blog</a>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    strapiPost(slug: { eq: $slug }) {
      titulo
      extracto
      imagen_destacada {
        alternativeText
        url
      }
      autor {
        Nombre
      }
      categorias {
        nombre
      }
      published_at
      cuerpo
      relacionados {
        titulo
        slug
        extracto
        published_at
      }
    }
  }
`
